export const columnsData = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "UID",
        accessor: "UID",
    },
    {
        Header: "VIEWS",
        accessor: "views",
    },
    {
        Header: "DIAMONDS",
        accessor: "diamonds",
    },
    {
        Header: "HOURS",
        accessor: "hours",
    },
];
