// Chakra imports
import { Box } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";
import React, { useEffect } from "react";
import { getHost } from "../../../api";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
export default function Settings() {
  const [hosts, setHosts] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const id = localStorage.getItem('agencyId');
    if(!id) {
      history.push('auth')
    }
    getStaffInfo(id)
  }, [])

  const getStaffInfo = (agenyId) => {
    try {
      getHost(agenyId).then(data => {
        console.log(data)
        setHosts(data)
      }).catch(err => {

      });

    } catch (err) {
      console.error(err);
    }

  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <DevelopmentTable
        columnsData={columnsDataDevelopment}
        tableData={hosts}
      />

    </Box>
  );
}
