export const weeklyColumnsData = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "UID",
        accessor: "UID",
    },
    {
        Header: "DIAMONDS",
        accessor: "diamonds",
    },
    {
        Header: "HOURS",
        accessor: "totalHour",
    },
    {
        Header: "D1",
        accessor: "d1",
    },

    {
        Header: "D2",
        accessor: "d2",
    },
    {
        Header: "D3",
        accessor: "d3",
    },
    {
        Header: "D4",
        accessor: "d4",
    },
    {
        Header: "D5",
        accessor: "d5",
    },
    {
        Header: "D6",
        accessor: "d6",
    },
    {
        Header: "D7",
        accessor: "d7",
    },
    {
        Header: "GIFT",
        accessor: "gift",
    },
    {
        Header: "AGENCY",
        accessor: "agency",
    },
    {
        Header: "BONUS",
        accessor: "bonus",
    },
    {
        Header: "TOTAL",
        accessor: "total",
    },
  
];
