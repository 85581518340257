import Axios from "axios";
import moment from "moment";
const axios = Axios.create({
    baseURL: 'https://parse1.pitalk.app',
});

export async function getHost(agencyId) {
    const { data } = await axios.get("/agency/hosts", {
        params: {
            agencyId,
        },
    });

    return data;
}

export async function login(email, password) {
    const { data } = await axios.post('/agency/login', {
        email, password
    });
    return data;
}

export async function report(tz, date, agencyId) {

    const { data } = await axios.get('statistics/report', {
        params: { tz, date, getraw: true, agencyId }
    })
    return data;
}

export async function weeklyReport(tz, date, agencyId) {
   
    var firstday = new Date(date.setDate(date.getDate() - date.getDay()+1));
    var last = new Date(firstday);
    var lastday = new Date(last.setDate(firstday.getDate() + 6));

    const { data } = await axios.get('statistics/report-week', {
        params: { tz, getraw: true, startdate: firstday, enddate: lastday, agencyId }
    });
    return data;
}

export async function downloadWeeklyReport(tz, date, agencyId) {
    var firstday = new Date(date.setDate(date.getDate() - date.getDay()+1));
    var last = new Date(firstday);
    var lastday = new Date(last.setDate(firstday.getDate() + 6));

    const { data } = await axios.get('statistics/report-week', {
        params: { tz, startdate: moment(firstday).format('YYYY-MM-DD'), enddate: moment(lastday).format('YYYY-MM-DD'), agencyId },
        headers: {
            "Content-Disposition": "attachment;filename=report.xls",
            "Content-type": "application/vnd.ms-excel"
        },
        responseType: 'blob', 
    });
    return data;
}