// Chakra imports
import { Box, HStack, Text } from "@chakra-ui/react";
import ReportTable from "views/admin/report/reporttable";
import { columnsData } from "views/admin/report/column";
import MiniCalendar from "components/calendar/MiniCalendar";
import React, { useEffect } from "react";
import { report } from "../../../api";
import { useState } from "react";
import { DatePicker as ChakraDatePicker } from "@orange_digital/chakra-datepicker";
import DatePicker2 from "components/calendar/Datepicker";
export default function ReportPage() {
    const [records, setRecords] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    useEffect(() => {
        getReport()
    }, [])

    const getReport = (date) => {
        const agencyId = localStorage.getItem('agencyId');
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        try {
            report(timeZone,date ? date : selectedDate, agencyId).then(data => {
                if (data)
                    setRecords(data)
            }).catch(err => {

            });

        } catch (err) {
            console.error(err);
        }

    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Box
                minW="100px"
                minH="50px"
                bg="white"
                borderRadius="base"
                boxShadow="base"
                d="flex"
                p="1"
            >
                <HStack>
                    <Text>Date</Text>
                        <DatePicker2 selectedDate={selectedDate} onChange={(d) => {
                            setSelectedDate(d);
                            getReport(d);
                        }} />
                </HStack>
            </Box>
            <ReportTable
                columnsData={columnsData}
                tableData={records}
            />

        </Box>
    );
}
