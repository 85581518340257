import "./styles.css";
import { useState } from "react";
import Calendar from "./calendar";
import Details from "./details";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useEffect } from "react";

export default function WeeklyReportPage() {
    // const [showDetails, setShowDetails] = useState(false);
    const [data, setData] = useState(new Date);

    useEffect(() => {
        setData(new Date);
    }, [])
    const showDetailsHandle = (dayStr) => {
        var curr = new Date(data); // get current date
        var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        
        if (dayStr.getTime() < firstday.getTime() || dayStr.getTime() > lastday.getTime()) {
            setData(dayStr);
            
        }
        console.log(dayStr)

    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Calendar showDetailsHandle={showDetailsHandle} />
            <Box
                minW="100px"
                minH="50px"
                bg="white"
                borderRadius="base"
                boxShadow="base"
                d="flex"

            > <Details data={data} /></Box>
        </Box>

    );
}